/*

On-page tabbed interface.

The first tab is selected by default, unless the URL includes #tab=something.

Required structure:

<div data-controller="tab" data-tab-active-classes="bg-highlight-500"
 data-tab-inactive-classes="bg-brand-500">
  <div>
    <div data-tab-target="title" data-tab="tab1" data-action="click->tab#click" data-tab-target="title" class="cursor-pointer">Tab 1</div>
    <div data-tab-target="title" data-tab="tab2" data-action="click->tab#click" data-tab-target="title" class="cursor-pointer">Tab 2</div>
  </div>
  <div data-tab-target="content" data-tab="tab1">Content 1</div>
  <div data-tab-target="content" data-tab="tab2">Content 2</div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class TabController extends Controller {
  static targets = ["title", "content"];

  connect() {
    if (window.location.hash.match(/#tab=/)) {
      var tab = window.location.hash.split("#tab=")[1]
      var $link = $(this.titleTargets).filter("[data-tab=\"" + tab + "\"]")
    } else {
      var $link = $($(this.titleTargets).first())
    }
    this.tabSelect($link)
  }

  click(e) {
    var $link = $(e.target)
    this.tabSelect($link)
  }

  tabSelect($link) {
    var tab = $link.data("tab")
    var inactiveClasses = this.element.dataset.tabInactiveClasses
    var activeClasses = this.element.dataset.tabActiveClasses

    $(this.contentTargets).addClass("hidden")
    $(this.titleTargets).removeClass(activeClasses).addClass(inactiveClasses)

    $link.addClass(activeClasses).removeClass(inactiveClasses)
    var $content = $(this.contentTargets).filter("[data-tab=\"" + tab + "\"]")
    $content.removeClass("hidden")

    window.location.hash = "#tab=" + tab
  }
}
